import "./App.scss";
import Home from "./Pages/Home/Home";
import Navbar from "./Component/Navbar/Navbar";
import Footer from "./Component/Footer/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Privacy from "./Pages/Privacy/Privacy";
function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<Privacy />} />
      </Routes>
      <a
        rel="noreferrer"
        target="_blank"
        href="https://api.whatsapp.com/send/?phone=6282332091818&text=Halo+Anthony,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20ini%20(Perumahan%20South%20Tresor)%20https://southtresormetland.id/&type=phone_number&app_absent=0"
      >
        <FloatingWhatsApp />
      </a>
      <Footer />
    </Router>
  );
}

export default App;
